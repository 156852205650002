<template>
<div class="flex flex-column flex-auto">
    <div class="p-5">
        <div class="grid">
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3">
                    <div class="grid">
                        <div class="col-4">
                            <Button v-if="selectedSearches.length===0" label="Select at Least One Search"
                                    class="w-full p-button-danger" disabled/>
                            <Button v-else class="w-full p-button-success" label="Download Selection" @click="showDownloadSelectedDialog=true"/>
                        </div>
                        <div class="col-4">
                            <Button v-if="!safeFilters()" label="Select Some Filters"
                                    class="w-full p-button-danger" disabled/>
                            <Button v-else class="w-full p-button-success" label="Download with Filters"
                                    @click="showDownloadFilteredDialog=true"/>
                        </div>
                        <div class="col-4">
                            <Button v-if="selectedSearches.length===0" label="Select at Least One Search"
                                    class="w-full p-button-danger" disabled/>
                            <Button v-else class="w-full p-button-success" label="Auto Submit" @click="autoSubmitSelected"/>
                        </div>
                        <div class="col-6">
                            <Button class="w-full p-button-secondary" label="Clear Filters" @click="resetFilters"
                                    :disabled="!areAllValuesNull(filters)"/>
                        </div>
                        <div class="col-6">
                            <Button class="w-full p-button-secondary" label="Clear Selected" @click="resetSelected"
                                    :disabled="selectedSearches.length===0"/>
                        </div>
                    </div>
                </div>
            </div>

<!--            <div v-if="showFilterInfo" class="col-12">-->
<!--                <div class="surface-card shadow-2 border-round p-3" style="background-color: yellow !important;">-->
<!--                    <p>-->
<!--                        <i class="pi pi-exclamation-triangle mr-2"></i>-->
<!--                        <span class="font-bold">Warning:</span> Results on filtered columns that would usually contain multiple values-->
<!--                        will only show your entered term. For example, if you filter by client A, and the results are actually A, B, C,-->
<!--                        only A will show.-->
<!--                    </p>-->
<!--                </div>-->
<!--            </div>-->

            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3">
                    <DataTable :value="searches" showGridlines lazy :loading="loading"
                               v-model:filters="filters" filterDisplay="menu" :rowClass="rowClass"
                               @page="onPage($event)" @filter="onFilter($event)" @select-all-change="onSelectAllChange" @row-select="onRowSelect" @row-unselect="onRowUnselect"
                               v-model:selection="selectedSearches" :total-records="totalSearches"
                               paginator :rows="lazyParams.rows" :rowsPerPageOptions="[100, 250, 500]"
                               paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                               currentPageReportTemplate="{first} to {last} of {totalRecords}">
                        <template #empty> No searches found. </template>
                        <template #loading> Loading search data. Please wait. </template>

                        <!-- columns -->
                        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                        <Column field="id" header="ID" :showFilterMatchModes="false">
                            <template #body="{ data }">
                                {{ data.id }}
                            </template>
                            <template #filter="{ filterModel }">
                                <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by ID" />
                            </template>
                        </Column>
                        <Column field="type" header="Type" :showFilterMatchModes="false">
                            <template #body="{ data }">
                                {{ data.type }}
                            </template>
                            <template #filter="{ filterModel }">
                                <Dropdown v-model="filterModel.value" :options="types" placeholder="Select One" class="p-column-filter" showClear>
                                </Dropdown>
                            </template>
                        </Column>
                        <Column field="assets" header="Assets" :showFilterMatchModes="false">
                            <template #body="{ data }">
                                {{ data.assets }}
                            </template>
                            <template #filter="{ filterModel }">
                                <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by asset" />
                            </template>
                        </Column>
                        <Column field="client" header="Client" :showFilterMatchModes="false">
                            <template #body="{ data }">
                                {{ data.client }}
                            </template>
                            <template #filter="{ filterModel }">
                                <Dropdown v-model="filterModel.value" :options="clients" placeholder="Select One" class="p-column-filter" showClear>
                                </Dropdown>
                            </template>
                        </Column>
                        <Column field="status" header="Status">
                            <template #body="{ data }">
                                <Tag class="w-full" :value="data.status" :severity="getSeverity(data.status)" />
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="statuses" placeholder="Select One" class="p-column-filter" style="min-width: 12rem" :showClear="true">
                                    <template #option="slotProps">
                                        <Tag :value="slotProps.option" :severity="getSeverity(slotProps.option)" />
                                    </template>
                                </Dropdown>
                            </template>
                        </Column>
                        <Column field="progress" header="Progress">
                            <template #body="{ data }">
                                <ProgressBar :value="data.progress" :showValue="false" style="height: 6px"></ProgressBar>
                            </template>
                        </Column>
                        <Column field="submitted" header="Submitted" dataType="boolean">
                            <template #body="{ data }">
                                <div style="text-align:center">
                                    <i class="pi text-2xl" :class="{
                                    'pi-check-circle text-green-500': data.submitted,
                                    'pi-times-circle text-red-500': !data.submitted
                                }"></i>
                                </div>
                            </template>
                            <template #filter="{ filterModel }">
                                <label for="verified-filter" class="font-bold"> Submitted </label>
                                <TriStateCheckbox v-model="filterModel.value" inputId="verified-filter" />
                            </template>
                        </Column>
                        <Column field="actions" header="Actions" :showFilter="false" :sortable="false">
                            <template #body="{ data }">
                                <div class="flex justify-content-between">
                                    <Button icon="pi pi-info" class="p-button-rounded p-button-sm p-button-outlined" @click="showInfo(data.id)"/>

                                    <!-- Animation if search needs filtering DISABLED FOR NOW -->
<!--                                    <Button v-if="searchesNeedingFiltering.includes(data.id)" icon="pi pi-flag" class="fadein animation-duration-1000 animation-iteration-infinite p-button-rounded p-button-secondary p-button-sm p-button-outlined"-->
<!--                                            @click="showDynamicFiltering(data.id)" :disabled="data.submitted || data.status !== 'Finished'"/>-->
                                    <Button icon="pi pi-flag" class="p-button-rounded p-button-secondary p-button-sm p-button-outlined" @click="showDynamicFiltering(data.id)" :disabled="data.submitted || data.status !== 'Finished'"/>

                                    <Button icon="pi pi-download" class="p-button-rounded p-button-success p-button-sm p-button-outlined" @click="downloadSearch(data.id)" :disabled="data.submitted"/>
                                    <Button icon="pi pi-trash" class="p-button-rounded p-button-critical p-button-sm p-button-outlined" @click="deleteSearch($event, data.id)"/>
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>

    <!-- Download selected dialog -->
    <Dialog v-model:visible="showDownloadSelectedDialog" modal header="Confirmation">
        <p>Confirm the below information before downloading.</p>
        <h3>Search IDs</h3>
        <p v-for="search in selectedSearches" :key="search.id">{{ search.id }}</p>

        <template #footer>
            <Button label="No" icon="pi pi-times" @click="showDownloadSelectedDialog=false" text />
            <Button label="Yes" icon="pi pi-check" @click="downloadSelected()" autofocus />
        </template>
    </Dialog>

    <!-- Download filtered dialog -->
    <Dialog v-model:visible="showDownloadFilteredDialog" modal header="Confirmation">
        <p>Confirm the below information before downloading.</p>
        <h3>Types</h3>
        <p v-for="type in filters.type.constraints" :key="type.value">{{ type.value }}</p>

        <h3>Assets</h3>
        <p v-for="asset in filters.assets.constraints" :key="asset.value">{{ asset.value }}</p>

        <h3>Clients</h3>
        <p v-for="client in filters.client.constraints" :key="client.value">{{ client.value }}</p>

        <template #footer>
            <Button label="No" icon="pi pi-times" @click="showDownloadSelectedDialog=false" text />
            <Button label="Yes" icon="pi pi-check" @click="downloadFiltered()" autofocus />
        </template>
    </Dialog>

    <SearchOverviewModal ref="searchOverviewModal" />
    <SubmitSearchOverview ref="submitSearchOverview" />
</div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import globals from "@/globals";
import SearchOverviewModal from "@/components/modals/SearchOverviewModal";
import SubmitSearchOverview from "@/components/modals/SubmitSearchOverview";
import router from "@/router";

export default {
    name: "SearchManager",
    components: {
        SearchOverviewModal,
        SubmitSearchOverview
    },

    data: function() {
        return {
            searches: [],
            searchesNeedingFiltering: [],
            totalSearches: 0,
            loading: false,

            showFilterInfo: false,
            showDownloadSelectedDialog: false,
            showDownloadFilteredDialog: false,

            selectedSearches: [],
            filters: {},
            types: [],
            clients: [],
            statuses: ['Finished', 'Filtering', 'Crawling', 'Waiting', 'Pending', 'Failed', 'Returned'],

            lazyParams: {},

            progressTimer: null,

            confettiTimeout: 2000,

            justSubmitted: [],
        }
    },

    created() {
        this.initFilters();
    },

    mounted() {
        // this.initFilters();
        this.getClients();
        this.getTypes();

        this.lazyParams = {
            first: 0,
            rows: 20,
            sortField: null,
            sortOrder: null,
            filters: this.filters
        };

        this.getSearches();

        this.progressTimer = setInterval(() => {
            this.getProgress();
        }, 15000);

        this.$emitter.on("search-manager-refresh", () => {
            console.debug("search-manager-refresh");
            this.ignoreSubmitted();
            this.getSearches();
        });
    },

    unmounted() {
        clearInterval(this.progressTimer);
    },

    methods: {
        ignoreSubmitted() {
            // Clear justSubmitted array
            this.justSubmitted = [];
            // Add all currently selected searches to the ignore list
            this.justSubmitted = this.justSubmitted.concat(this.selectedSearches.map(search => search.id));

            console.debug("Ignoring just submitted searches", this.justSubmitted);
        },

        initFilters() {
            this.filters = {
                id: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                type: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                assets: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                client: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                // progress: { value: [0, 100], matchMode: FilterMatchMode.BETWEEN },
                submitted: { value: false, matchMode: FilterMatchMode.EQUALS }
            }
        },

        reset() {
            this.searches = [];
            this.totalSearches = 0;
            this.searchesNeedingFiltering = [];
        },

        getSearches() {
            this.loading = true;

            this.reset();

            console.log("getting searches")
            this.$factory.searchPage.getSearches(this.lazyParams).then(data => {
                this.searches = data.searches;
                this.totalSearches = data.totalSearches;
                this.searchesNeedingFiltering = data.filteringNeeded;
            }).then(() => {
                console.debug("Searches", this.searches);
                console.debug("Total Searches", this.totalSearches);
                console.debug("Searches Needing Filtering", this.searchesNeedingFiltering);

                this.searches.forEach(element => {
                    // Convert assets array to string
                    if (element.assets.length > 1) {
                        element.assets = element.assets[0] + " & " + (element.assets.length-1) + " more";
                    } else if (element.assets[0] === null) {
                        element.assets = "Pending";
                    } else if (element.assets.length === 1) {
                        element.assets = element.assets[0];
                    } else {
                        element.assets = "Unknown";
                    }

                    // Convert client array to string
                    if (element.client.length > 1) {
                        element.client = element.client[0] + " & " + (element.client.length-1) + " more";
                    } else if (element.client[0] === null) {
                        element.client = "Pending";
                    } else if (element.client.length === 1) {
                        element.client = element.client[0];
                    } else {
                        element.client = "Unknown";
                    }
                });

                // Hide by default unfinished relook jobs and link removal jobs
                this.searches = this.searches.filter(search => !((search.type === "relook" || search.type === "link_removal") && search.status !== "Finished"));
                // Also hide justSubmitted searches
                this.searches = this.searches.filter(search => !this.justSubmitted.includes(search.id));

                this.getProgress();
            }).finally(() => {
                this.loading = false;
            });
        },

        getProgress() {
            let unfinishedSearches = this.searches.filter(search => search.status !== 'Finished' && search.status !== 'Failed').map(search => search.id);
            if (unfinishedSearches.length === 0) { return }

            this.$factory.searchPage.getSearchProgress(unfinishedSearches).then(data => {
                Object.keys(data).forEach(key => {
                    let search = this.searches.find(search => search.id === parseInt(key) );
                    search.progress = data[key];
                });
            })

            // set finished searches progress to 100
            let finishedSearches = this.searches.filter(search => search.status === 'Finished' || search.status === 'Failed').map(search => search.id);
            if (finishedSearches.length === 0) { return }

            finishedSearches.forEach(id => {
                let search = this.searches.find(search => search.id === id);
                search.progress = 100;
            })

        },

        getClients() {
            this.$factory.searchPage.getClients().then(data => {
                this.clients = data;
            })
        },

        getTypes() {
            this.$factory.searchPage.getTypes().then(data => {
                this.types = data;
            })
        },

        getSeverity(status) {
            switch (status) {
                case 'Finished':
                    return 'success';
                case 'Filtering' || 'Crawling' || 'Returned':
                    return 'warning';
                case 'Pending' || 'Waiting':
                    return 'info';
                default:
                    return 'danger';
            }
        },

        showInfo(s_id) {
            console.debug('Show info for search id: ' + s_id);
            this.$refs.searchOverviewModal.show(s_id);
        },

        showDynamicFiltering(s_id) {
            console.debug('Show dynamic filtering for search id: ' + s_id);
            router.push({ name: 'Dynamic Filtering', params: { searchId: s_id } });
        },

        deleteSearch(event, s_id) {
            s_id = parseInt(s_id);

            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to delete search: ' + s_id + '?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.loading = true;
                    this.$factory.adminPage.deleteSearch(s_id).then(() => {
                        console.debug("Search: " + s_id + " deleted");
                        this.getSearches();
                        this.loading = false;
                    });
                },
                reject: () => { }
            });
        },

        rowClass(data) {
            // return data.submitted === true ? "bg-green-100" : "bg-orange-200"
            return data.submitted === true ? "surface-200" : ""
        },

        startConfetti() {
            this.loading = true;
            // this.$confetti.start();
        },

        stopConfetti() {
            // this.$confetti.stop();
        },

        downloadSearch(s_id) {
            console.debug('Download search id: ' + s_id);

            let selectedSearch = this.searches.find(x => x.id === s_id);

            let client = this.searches.find(x => x.id === s_id).client.replace("  ", "-").toLowerCase();
            let account = this.getSelectedAccount([selectedSearch]);

            this.$factory.searchPage.getSearchTxt([s_id], client, account).then(() => {
                console.debug("Search: " + s_id + " finished exporting");
                this.getSearches();
                this.loading = false;
            });
        },

        downloadSelected() {
            if (!this.allSelectedSameClient()) {
                this.$emitter.emit("bad-toast", "Cannot download different clients together");
                return;
            } else if (!this.allSelectedSameAccount()) {
                this.$emitter.emit("bad-toast", "Cannot download different accounts together");
                return;
            }
            this.startConfetti();

            this.showDownloadSelectedDialog = false;
            console.debug('Download selected searches');

            let selectedIds = [];
            this.selectedSearches.forEach(element => {
                selectedIds.push(element.id);
            });

            let client = this.selectedSearches[0].client.replace("  ", "-").toLowerCase();
            let account = this.getSelectedAccount(this.selectedSearches);

            if (account === 1) {
                this.$factory.searchPage.getSearchTxt(selectedIds, client, account).then(() => {
                    console.debug("Searches finished exporting");
                    this.getSearches();
                    this.selectedSearches = [];
                    this.loading = false;

                    setTimeout(this.stopConfetti, this.confettiTimeout);
                });
            } else {
                this.$factory.searchPage.getSearchZip(selectedIds, client, account).then(() => {
                    console.debug("Searches finished exporting");
                    this.getSearches();
                    this.selectedSearches = [];
                    this.loading = false;

                    setTimeout(this.stopConfetti, this.confettiTimeout);
                });
            }
        },

        autoSubmitSelected() {
            if (!this.allSelectedSameClient()) {
                this.$emitter.emit("bad-toast", "Cannot auto submit different clients together");
                return;
            } else if (!this.allSelectedSameAccount()) {
                this.$emitter.emit("bad-toast", "Cannot auto submit different accounts together");
                return;
            }

            console.debug('Download selected searches');

            let selectedIds = [];
            this.selectedSearches.forEach(element => {
                selectedIds.push(element.id);
            });

            let client = this.selectedSearches[0].client;
            let account = this.getSelectedAccount(this.selectedSearches);

            this.$refs.submitSearchOverview.show(selectedIds, client, account);
        },

        downloadFiltered() {
            if (this.filters.client.constraints.length > 1) {
                this.$emitter.emit("bad-toast", "Cannot download different clients together");
                return;
            } else if (this.allSelectedSameAccount()) {
                this.$emitter.emit("bad-toast", "Cannot download different accounts together");
                return;
            }

            this.startConfetti();
            this.showDownloadFilteredDialog = false;
            console.debug('Download filtered searches', this.filters);

            let client = this.filters.client.constraints[0].value;
            let assets = [];
            let types = [];
            let account = this.getSelectedAccountFromFilter();

            this.filters.assets.constraints.forEach(element => {
                if (element.value !== null) {
                    assets.push(element.value)
                }
            });

            this.filters.type.constraints.forEach(element => {
                if (element.value !== null) {
                    types.push(element.value)
                }
            });

            console.debug("Requesting searches with assets: " + assets + " and client: " + client);

            this.$factory.searchPage.getSearchTxtFiltered(assets, client, types, account).then(() => {
                console.debug("Searches finished exporting");
                this.getSearches();
                this.loading = false;
                setTimeout(this.stopConfetti, this.confettiTimeout);
            });
        },

        onPage(event) {
            console.debug('Get page of searches from database', event);
            this.lazyParams.first = event.first;
            this.lazyParams.rows = event.rows;
            this.getSearches();
        },

        areAllValuesNull(constraints) {
            for (let prop in constraints) {
                // eslint-disable-next-line no-prototype-builtins
                if (constraints.hasOwnProperty(prop)) {
                    const constraint = constraints[prop];
                    if (Array.isArray(constraint.constraints)) {
                        const allNull = constraint.constraints.every(constraintItem => constraintItem.value === null);
                        if (!allNull) {
                            return true;
                        }
                    } else {
                        if (constraint.value !== null) {
                            return true;
                        }
                    }
                }
            }
            return false;
        },

        onFilter(event) {
            console.debug('Get filtered searches from database', event);

            // Change unsubmitted filter if client is selected
            // if (event.filters.client.constraints[0].value !== null) {
            //     this.filters.submitted.value = false;
            // }

            this.lazyParams.first = 0;
            this.lazyParams.filters = this.filters;

            // Show filter info
            this.showFilterInfo = this.areAllValuesNull(event.filters);

            console.debug(this.showFilterInfo);

            this.getSearches();
        },

        onSelectAllChange(event) {
            console.debug('Select all change')
            this.selectAll = event.checked;
            if (this.selectAll) {
                this.selectedSearches = this.searches;
            } else {
                this.selectedSearches = [];
            }
        },

        safeFilters() {
            // Cannot just select a type
            if (this.filters.type.constraints[0].value === null && this.filters.assets.constraints[0].value === null ||
                this.filters.type.constraints[0].value === null && this.filters.client.constraints[0].value === null
            ) {
                return false;
            } else {
                // Are all types selected of the same type
                let allFirstType = this.filters.type.constraints.every(element => !(globals.secondAccountTypes.includes(element.value) || element.value.includes("as_")));
                let allSecondType = this.filters.type.constraints.every(element => (globals.secondAccountTypes.includes(element.value) || element.value.includes("as_")));

                if (!(allFirstType ^ allSecondType)) {
                    return false;
                }
            }

            // True if assets or client filter is selected
            return this.filters.assets.constraints[0].value !== null || this.filters.client.constraints[0].value !== null
        },

        allSelectedSameClient() {
            let client = this.selectedSearches[0].client;
            return this.selectedSearches.every(element => element.client === client && !element.client.match("& [0-9]+ more"))
        },

        allSelectedSameAccount() {
            let allFirstType = this.selectedSearches.every(element => !(globals.secondAccountTypes.includes(element.type) || element.type.includes("as_")));
            let allSecondType = this.selectedSearches.every(element => (globals.secondAccountTypes.includes(element.type) || element.type.includes("as_")));
            return allFirstType ^ allSecondType;
        },

        getSelectedAccount(elements) {
            let allFirstType = elements.every(element => !(globals.secondAccountTypes.includes(element.type) || element.type.includes("as_")));
            return allFirstType ? 1 : 2;
        },

        getSelectedAccountFromFilter() {
            let allFirstType = this.filters.type.constraints.every(element => !(globals.secondAccountTypes.includes(element.value) || element.value.includes("as_")));
            return allFirstType ? 1 : 2;
        },

        onRowSelect() {
            this.selectAll = this.selectedSearches.length === this.totalSearches;

            // Check if all selected searches are the same client
            if (!this.allSelectedSameClient()) {
                this.$emitter.emit("bad-toast", "Selected searches must be from the same client");
            } else if (!this.allSelectedSameAccount()) {
                this.$emitter.emit("bad-toast", "Selected searches must be for the same account");
            }
        },

        onRowUnselect() {
            this.selectAll = false;
        },

        resetFilters() {
            this.showFilterInfo = false;
            this.initFilters();
            this.filters.submitted.value = null;
            this.lazyParams.filters = this.filters;
            this.getSearches();
            this.$emitter.emit("good-toast", "Selected filters reset");
        },

        resetSelected() {
            this.selectedSearches = [];
            this.selectAll = false;
            this.$emitter.emit("good-toast", "Selected searches reset");
        },
    },


}
</script>

<style scoped>

</style>
