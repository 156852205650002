<template>
<div>
    <Dialog v-model:visible="visible" modal maximizable :header="'Search Overview for Search ' + searchId" :style="{ width: '50vw' }">
        <TabView>
            <TabPanel header="Overview">
                <div v-if="overviewData !== null">
                    <DataTable :value="overviewData">
                        <Column field="name" header="Asset"/>
                        <Column field="total" header="Total"/>
                        <Column field="filtered" header="Filtered"/>
                        <Column field="results" header="Results"/>
                    </DataTable>

                    <div class="grid">
                        <div class="col-6">
                            <p><b>Run By:</b> {{ runby }}</p>
                        </div>
                        <div class="col-6">
                            <p><b>Date Created:</b> {{ rundate }}</p>
                        </div>
                        <div class="col-6">
                            <p><b>Submitted By:</b> {{ subby }}</p>
                        </div>
                        <div class="col-6">
                            <p><b>Submission Date:</b> {{ subdate }}</p>
                        </div>
                        <div v-show="assetSpecificNeeded === true" class="col-12">
                            <p><mark>
                                An asset in this search has no asset specific filters set. You may want to consider adding some to improve the search.
                            </mark></p>
                        </div>
                        <div v-show="dynamicFilteringUsed === true" class="col-12">
                            <p><mark>
                                Dynamic filtering has been used. All figures will represent the original values before dynamic filtering took place
                            </mark></p>
                        </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Countries">
                <div v-if="countryData === null || Object.keys(countryData).length === 0">
                    <p>No data found for this search</p>
                </div>
                <div v-else>
                    <div v-for="(movie, key) in countryData" :key="key">
                        <h3>{{ key }}</h3>
                        <DataTable :value="movie" scrollable scrollHeight="400px">
                            <Column field="country" header="Country Code"/>
                            <Column field="total" header="Total"/>
                            <Column field="filtered" header="Filtered"/>
                            <Column field="results" header="Results"/>
                        </DataTable>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Crawled">
                <div v-if="crawledLinks === null || crawledLinks === undefined || Object.keys(crawledLinks).length === 0">
                    <p>No crawled links found for this search</p>
                </div>
                <div v-else>
                    <div v-for="(movie, key) in crawledLinks" :key="key">
                        <h3>{{ key }}</h3>
                        <DataTable :value="movie" scrollable scrollHeight="400px" paginator :rows="20">
                            <Column field="link" header="Link"/>
                        </DataTable>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Filtered">
                <div v-if="filteredLinks === null || Object.keys(filteredLinks).length === 0">
                    <p>No data filtered links found for this search</p>
                </div>
                <div v-else>
                    <div v-for="(movie, key) in filteredLinks" :key="key">
                        <h3>{{ key }}</h3>
                        <DataTable v-model:filters="filteredLinksFilter" :value="movie" scrollable scrollHeight="400px" paginator :rows="20"
                                   filterDisplay="row" :globalFilterFields="['filter']">
                            <Column field="filter" header="Filter" :showFilterMenu="false">
                                <template #filter="{ filterModel, filterCallback }">
                                    <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="filterNames"
                                              placeholder="Select a Filter" class="p-column-filter"
                                              style="min-width: 14rem">
                                    </Dropdown>
                                </template>
                            </Column>
                            <Column field="link" header="Link"/>
                        </DataTable>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Results">
                <div v-if="assetLinks === null || Object.keys(assetLinks).length === 0">
                    <p>No successful links found for this search</p>
                </div>
                <div v-else>
                    <div v-for="(movie, key) in assetLinks" :key="key">
                        <h3>{{ key }}</h3>
                        <DataTable :value="movie" scrollable scrollHeight="400px" paginator :rows="20">
                            <Column field="link" header="Link"/>
                        </DataTable>
                    </div>
                </div>
            </TabPanel>
        </TabView>
    </Dialog>

</div>
</template>

<script>
import {FilterMatchMode} from "primevue/api";

export default {
    name: "SearchOverviewModal",

    data: function() {
        return {
            visible: false,
            loading: false,
            searchId: null,
            overviewData: null,
            countryData: null,
            crawledLinks: null,
            filteredLinks: null,
            filterNames: null,
            assetLinks: null,
            dynamicFilteringUsed: false,
            assetSpecificNeeded: false,

            runby: "",
            rundate: "",
            subby: "",
            subdate: "",

            filteredLinksFilter: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                filter: {value: null , matchMode: FilterMatchMode.EQUALS }
            }

        }
    },

    methods: {
        init() {
            this.visible = false;
            this.searchId = null;
            this.overviewData = null;
            this.countryData = null;
            this.crawledLinks = null;
            this.filteredLinks = null;
            this.filterNames = null;
            this.assetLinks = null;

            this.dynamicFilteringUsed = false;
            this.assetSpecificNeeded = false;
        },

        show(s_id) {
            this.init();
            this.searchId = s_id;
            this.getOverviewData();
            this.visible = true;
        },

        getOverviewData() {
            this.$factory.searchPage.getSearchMetrics(this.searchId).then((response) => {
                return response
            }).then((data) => {
                this.overviewData = data.overview;
                this.runby = this.overviewData[0].runby;
                this.rundate = this.overviewData[0].rundate;
                this.subby = this.overviewData[0].subby;
                this.subdate = this.overviewData[0].subdate;

                this.countryData = data.countries;
                console.debug("Country data:", this.countryData);

                this.filteredLinks = data.filtered;
                this.filterNames = data.filternames;

                this.dynamicFilteringUsed = data.dynamic;
                this.assetSpecificNeeded = data.assetspecificneeded;

                this.assetLinks = data.assetlinks;

                this.crawledLinks = {};
                this.overviewData.forEach((item) => {
                    this.crawledLinks[item.name] = [].concat(this.filteredLinks[item.name], this.assetLinks[item.name]);
                });
            });
        },



    },
}
</script>

<style scoped>

</style>
