<template>
<Dialog v-model:visible="visible" modal :header="'Auto submit for ' + client + ' under account ' + account" :style="{ width: '75vw' }"
        maximizable>
    <Button class="w-full mt-2" label="Submit" @click="submit"/>

    <h3 class="mt-3">Approved URLs</h3>
    <DataTable :value="urls">
        <Column field="searchid" header="Search ID"></Column>
        <Column field="name" header="Name"></Column>
        <Column value="Delete" style="width: 8rem" bodyStyle="text-align:center">
            <template #body="{ data }">
                <div class="w-full text-center flex justify-content-between align-items-center">
                    <i class="border-circle hover:text-900 hover:surface-100 cursor-pointer pi pi-trash" @click="removeApproved(data)"
                        v-tooltip.left="'Remove URL from this search only'"/>

                    <i class="border-circle hover:text-900 hover:surface-100 cursor-pointer" @click="addHardExclude(data)"
                        v-tooltip.left="'Remove URL from this search and also add the domain to Hard Exclude. This will refilter all other pending searches'">
                        <font-awesome-icon icon="hammer" />
                    </i>

                    <i class="border-circle hover:text-900 hover:surface-100 cursor-pointer pi pi-undo" @click="addSuspiciousDomain(data)"
                       v-tooltip.left="'Remove URL from this search and also remove from Suspicious Domains.'"/>

                    <i class="border-circle hover:text-900 hover:surface-100 cursor-pointer pi pi-plus" @click="addNewSuspiciousDomain(data)"
                       v-tooltip.left="'Keep this URL and also add the domain to Suspicious Domains.'"/>

                    <i v-if="account === 1" class="border-circle hover:text-900 hover:surface-100 cursor-pointer" style="font-style: normal !" @click="addNewSpamDomain(data)"
                       v-tooltip.left="'Move this URL into a new spam search to be submitted under account 2.'">2</i>
                </div>
            </template>
        </Column>
        <Column value="Status" style="width: 6rem" bodyStyle="text-align:center">
            <template #body="{ data }">
                <div v-if="data.verified">
                    <i class="pi pi-check-circle text-green-500 text-success"/>
                </div>
                <div v-else-if="data.domaincount > 10">
                    <i class="pi pi-question-circle text-yellow-900 text-warning"/>
                </div>
                <div v-else>
                    <i class="pi pi-times-circle text-red-900"/>
                </div>
            </template>
        </Column>
        <Column field="link" header="Link">
            <template #body="{ data }">
                <a :class="getLinkClass" :href="data.link" target="_blank">{{ data.link }}</a>
            </template>
        </Column>
    </DataTable>

    <h3 class="mt-3">Removed URLs</h3>
    <DataTable :value="removed">
        <Column field="searchId" header="Search ID"></Column>
        <Column field="name" header="Name"></Column>
        <Column value="Delete" style="width: 10%; min-width: 2rem" bodyStyle="text-align:center">
            <template #body="{ data }">
                <div class="border-circle hover:text-900 hover:surface-100 cursor-pointer w-full text-center">
                    <i class="pi pi-trash" @click="removeRemoved(data)"/>
                </div>
            </template>
        </Column>
        <Column value="Status" style="width: 10%; min-width: 2rem" bodyStyle="text-align:center">
            <template #body="{ data }">
                <div v-if="data.verified">
                    <i class="pi pi-check-circle text-green-500 text-success"/>
                </div>
                <div v-else-if="data.domaincount > 10">
                    <i class="pi pi-question-circle text-yellow-900 text-warning"/>
                </div>
                <div v-else>
                    <i class="pi pi-times-circle text-red-900"/>
                </div>
            </template>
        </Column>
        <Column field="link" header="Link">
            <template #body="{ data }">
                <a :class="getLinkClass" :href="data.link" target="_blank">{{ data.link }}</a>
            </template>
        </Column>
    </DataTable>

    <h3 class="mt-3">Hard Exclude Domains</h3>
    <DataTable :value="hardExcludes">
        <Column field="searchid" header="Search ID"></Column>
        <Column field="name" header="Name"></Column>
        <Column value="Delete" style="width: 8rem" bodyStyle="text-align:center">
            <template #body="{ data }">
                <div class="w-full text-center flex justify-content-between">
                    <i class="border-circle hover:text-900 hover:surface-100 cursor-pointer pi pi-trash" @click="removeHardExclude(data)"
                       v-tooltip.left="'Remove URL from new Hard Excludes and add it back into the accepted URLs'"/>
                </div>
            </template>
        </Column>
        <Column value="Status" style="width: 6rem" bodyStyle="text-align:center">
            <template #body="{ data }">
                <div v-if="data.verified">
                    <i class="pi pi-check-circle text-green-500 text-success"/>
                </div>
                <div v-else-if="data.domaincount > 10">
                    <i class="pi pi-question-circle text-yellow-900 text-warning"/>
                </div>
                <div v-else>
                    <i class="pi pi-times-circle text-red-900"/>
                </div>
            </template>
        </Column>
        <Column field="link" header="Link">
            <template #body="{ data }">
                <a :class="getLinkClass" :href="data.link" target="_blank">{{ data.link }}</a>
            </template>
        </Column>
    </DataTable>

    <h3 class="mt-3">Remove from Suspicious Domains</h3>
    <DataTable :value="suspiciousDomains">
        <Column field="searchid" header="Search ID"></Column>
        <Column field="name" header="Name"></Column>
        <Column value="Delete" style="width: 8rem" bodyStyle="text-align:center">
            <template #body="{ data }">
                <div class="w-full text-center flex justify-content-between">
                    <i class="border-circle hover:text-900 hover:surface-100 cursor-pointer pi pi-trash" @click="removeSuspiciousDomain(data)"
                       v-tooltip.left="'Remove URL from to be removed from Suspicious Domains and add it back into the accepted URLs'"/>
                </div>
            </template>
        </Column>
        <Column value="Status" style="width: 6rem" bodyStyle="text-align:center">
            <template #body="{ data }">
                <div v-if="data.verified">
                    <i class="pi pi-check-circle text-green-500 text-success"/>
                </div>
                <div v-else-if="data.domaincount > 10">
                    <i class="pi pi-question-circle text-yellow-900 text-warning"/>
                </div>
                <div v-else>
                    <i class="pi pi-times-circle text-red-900"/>
                </div>
            </template>
        </Column>
        <Column field="link" header="Link">
            <template #body="{ data }">
                <a :class="getLinkClass" :href="data.link" target="_blank">{{ data.link }}</a>
            </template>
        </Column>
    </DataTable>

    <h3 class="mt-3">Add to Suspicious Domains</h3>
    <DataTable :value="newSuspiciousDomains">
        <Column field="searchid" header="Search ID"></Column>
        <Column field="name" header="Name"></Column>
        <Column value="Delete" style="width: 8rem" bodyStyle="text-align:center">
            <template #body="{ data }">
                <div class="w-full text-center flex justify-content-between">
                    <i class="border-circle hover:text-900 hover:surface-100 cursor-pointer pi pi-trash" @click="removeNewSuspiciousDomain(data)"
                       v-tooltip.left="'Remove URL from new Suspicious Domains and add it back into the accepted URLs'"/>
                </div>
            </template>
        </Column>
        <Column value="Status" style="width: 6rem" bodyStyle="text-align:center">
            <template #body="{ data }">
                <div v-if="data.verified">
                    <i class="pi pi-check-circle text-green-500 text-success"/>
                </div>
                <div v-else-if="data.domaincount > 10">
                    <i class="pi pi-question-circle text-yellow-900 text-warning"/>
                </div>
                <div v-else>
                    <i class="pi pi-times-circle text-red-900"/>
                </div>
            </template>
        </Column>
        <Column field="link" header="Link">
            <template #body="{ data }">
                <a :class="getLinkClass" :href="data.link" target="_blank">{{ data.link }}</a>
            </template>
        </Column>
    </DataTable>

    <h3 class="mt-3">Add to Spam Domains</h3>
    <DataTable :value="spamDomains">
        <Column field="searchid" header="Search ID"></Column>
        <Column field="name" header="Name"></Column>
        <Column value="Delete" style="width: 8rem" bodyStyle="text-align:center">
            <template #body="{ data }">
                <div class="w-full text-center flex justify-content-between">
                    <i class="border-circle hover:text-900 hover:surface-100 cursor-pointer pi pi-trash" @click="removeNewSpamDomain(data)"
                       v-tooltip.left="'Remove URL from new Spam Domains and add it back into the accepted URLs'"/>
                </div>
            </template>
        </Column>
        <Column value="Status" style="width: 6rem" bodyStyle="text-align:center">
            <template #body="{ data }">
                <div v-if="data.verified">
                    <i class="pi pi-check-circle text-green-500 text-success"/>
                </div>
                <div v-else-if="data.domaincount > 10">
                    <i class="pi pi-question-circle text-yellow-900 text-warning"/>
                </div>
                <div v-else>
                    <i class="pi pi-times-circle text-red-900"/>
                </div>
            </template>
        </Column>
        <Column field="link" header="Link">
            <template #body="{ data }">
                <a :class="getLinkClass" :href="data.link" target="_blank">{{ data.link }}</a>
            </template>
        </Column>
    </DataTable>

    <Button class="w-full mt-2" label="Submit" @click="submit"/>
</Dialog>
</template>

<script>
export default {
    name: "SubmitSearchOverview",

    data() {
        return {
            visible: false,
            searchIds: [],
            client: null,
            account: null,

            urls: [],
            removed: [],

            hardExcludes: [],
            suspiciousDomains: [],
            newSuspiciousDomains: [],
            spamDomains: [],

            currentTheme: this.$store.state.theme,
        }
    },

    computed: {
        getLinkClass() {
            if (this.currentTheme === 'light') {
                return 'light-theme-link';
            } else {
                return 'dark-theme-link';
            }
        }
    },

    methods: {
        show(searchIds, client, account) {
            this.reset();
            this.searchIds = searchIds;
            this.client = client;
            this.account = account;
            this.visible = true;

            this.getUrls();
        },

        reset() {
            this.searchIds = [];
            this.client = null;
            this.account = null;
            this.urls = [];
            this.removed = [];
            this.hardExcludes = [];
            this.suspiciousDomains = [];
            this.newSuspiciousDomains = [];
            this.spamDomains = [];
        },

        getUrls() {
            this.$factory.searchPage.getMultiAllAssetLinks(this.searchIds).then((response) => {
                return response
            }).then((data) => {
                if (data !== null) {
                    this.urls = data;
                }
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
                this.loadingResults = false;
            });
        },

        removeApproved(data) {
            // Remove from urls and add to removed
            this.urls = this.urls.filter((url) => {
                return url.link !== data.link;
            });
            this.removed.push(data);
        },

        removeRemoved(data) {
            // Remove from removed and add to urls
            this.removed = this.removed.filter((url) => {
                return url.link !== data.link;
            });
            this.urls.push(data);
        },

        addHardExclude(data) {
            console.debug("Adding hard exclude for " + data.link);
            // Find the domain from the link
            let domain = new URL(data.link).hostname;

            // Remove all urls with this domain from urls and add to hardExcludes
            let removedUrls = this.urls.filter((url) => {
                return new URL(url.link).hostname === domain;
            });

            // Add to hardExcludes
            this.hardExcludes = this.hardExcludes.concat(removedUrls);

            // Remove from urls
            this.urls = this.urls.filter((url) => {
                return new URL(url.link).hostname !== domain;
            });
        },

        removeHardExclude(data) {
            console.debug("Removing hard exclude for " + data.link);
            // Find the domain from the link
            let domain = new URL(data.link).hostname;

            // Get all urls with this domain from hardExcludes
            let removedUrls = this.hardExcludes.filter((url) => {
                return new URL(url.link).hostname === domain;
            });

            // Remove from hardExcludes
            this.hardExcludes = this.hardExcludes.filter((url) => {
                return new URL(url.link).hostname !== domain;
            });

            // Add to urls
            this.urls = this.urls.concat(removedUrls);
        },

        addSuspiciousDomain(data) {
            console.debug("Removing suspicious domain for " + data.link)
            // Find the domain from the link
            let domain = new URL(data.link).hostname;

            // Get all urls with this domain from suspiciousDomains
            let removedUrls = this.urls.filter((url) => {
                return new URL(url.link).hostname === domain;
            });

            // Add to suspiciousDomains
            this.suspiciousDomains = this.suspiciousDomains.concat(removedUrls);

            // Remove from URLs
            this.urls = this.urls.filter((url) => {
                return new URL(url.link).hostname !== domain;
            });
        },

        removeSuspiciousDomain(data) {
            console.debug("Removing suspicious domain for " + data.link)
            // Find the domain from the link
            let domain = new URL(data.link).hostname;

            // Get all urls with this domain from suspiciousDomains
            let removedUrls = this.suspiciousDomains.filter((url) => {
                return new URL(url.link).hostname === domain;
            });

            // Remove from suspiciousDomains
            this.suspiciousDomains = this.suspiciousDomains.filter((url) => {
                return new URL(url.link).hostname !== domain;
            });

            // Add to URLs
            this.urls = this.urls.concat(removedUrls);
        },

        addNewSuspiciousDomain(data) {
            console.debug("Adding new suspicious domain for " + data.link)
            // Find the domain from the link
            let domain = new URL(data.link).hostname;

            // Remove all urls with this domain from urls and add to new suspiciousDomains
            let removedUrls = this.urls.filter((url) => {
                return new URL(url.link).hostname === domain;
            });

            // Add to new suspiciousDomains
            this.newSuspiciousDomains = this.newSuspiciousDomains.concat(removedUrls);

            // Remove from urls
            this.urls = this.urls.filter((url) => {
                return new URL(url.link).hostname !== domain;
            });
        },

        removeNewSuspiciousDomain(data) {
            console.debug("Removing new suspicious domain for " + data.link)
            // Find the domain from the link
            let domain = new URL(data.link).hostname;

            // Get all urls with this domain from new suspiciousDomains
            let removedUrls = this.newSuspiciousDomains.filter((url) => {
                return new URL(url.link).hostname === domain;
            });

            // Remove from new suspiciousDomains
            this.newSuspiciousDomains = this.newSuspiciousDomains.filter((url) => {
                return new URL(url.link).hostname !== domain;
            });

            // Add to URLs
            this.urls = this.urls.concat(removedUrls);
        },

        addNewSpamDomain(data) {
            console.debug("Adding new spam domain for " + data.link)
            // Find the domain from the link
            let domain = new URL(data.link).hostname;

            // Remove all urls with this domain from urls and add to new spamDomains
            let removedUrls = this.urls.filter((url) => {
                return new URL(url.link).hostname === domain;
            });

            // Add to new spamDomains array
            this.spamDomains = this.spamDomains.concat(removedUrls);

            // Remove from urls
            this.urls = this.urls.filter((url) => {
                return new URL(url.link).hostname !== domain;
            });
        },

        removeNewSpamDomain(data) {
            console.debug("Removing new spam domain for " + data.link)
            // Find the domain from the link
            let domain = new URL(data.link).hostname;

            // Get all urls with this domain from new spamDomains
            let removedUrls = this.spamDomains.filter((url) => {
                return new URL(url.link).hostname === domain;
            });

            // Remove from new spamDomains
            this.spamDomains = this.spamDomains.filter((url) => {
                return new URL(url.link).hostname !== domain;
            });

            // Add to URLs
            this.urls = this.urls.concat(removedUrls);
        },

        submit() {
            let submissionObject = {
                selectedIds: this.searchIds,
                client: this.client,
                account: this.account,
                approved: this.urls,
                removed: this.removed,
                hardexclude: this.hardExcludes,
                suspicious: this.suspiciousDomains,
                newsuspicious: this.newSuspiciousDomains,
                spam: this.spamDomains
            }

            console.debug(submissionObject);
            this.$factory.export.autoSubmitSearches(submissionObject).then(() => {
                console.debug("Searches auto submitted");
                this.$emitter.emit("good-toast", "Searches auto submitted");
                this.$emitter.emit("search-manager-refresh");
            }).catch((error) => {
                console.error(error);
                this.$emitter.emit("bad-toast", "Error submitting searches");
            }).finally(() => {
                this.visible = false;
            });
        }
    },

    watch: {
        '$store.state.theme': function (newTheme) {
            this.currentTheme = newTheme;
        }
    }
}
</script>

<style scoped>
.light-theme-link {
  color: black;
}
.light-theme-link:visited {
  color: darkgray;
}
.dark-theme-link {
  color: white;
}
.dark-theme-link:visited {
  color: lightgray;
}
</style>
